export var SITEROLE_ALERTUSR = 6;
export var SITEROLE_RELOWNER = 5;
export var SITEROLE_READONLY = 0;
export var SITEROLE_AUDITOR = 1;
export var SITEROLE_USER = 2;
export var SITEROLE_MANAGER = 3;
export var SITEROLE_ADMIN = 4;
export var SITEROLE_EXTERNAL = 8;
export var SITEROLE_VRM = 9;
export var USER_ACTIVE = 0;
export var USER_DELETED = 66;
export var USER_INACTIVE = 99;
export var CLIENT_ACTIVE = 0;
export var CLIENT_DISABLED = 1;
export var CLIENT_INACTIVE = 99;
export var VENDOR_ACTIVE = 0;
export var VENDOR_PENDING = 1;
export var VENDOR_ARCHIVED = 99;
export var BUSINESSUNIT_ACTIVE = 0;
export var BUSINESSUNIT_DELETED = 99;
export var FOURTHPARTY_ACTIVE = 0;
export var FOURTHPARTY_DELETED = 99;
export var PRIOR_ACTIVE = 0;
export var PRIOR_DELETED = 99;
export var TASK_TYPE_ACTIVE = 0;
export var TASK_TYPE_DELETED = 99;
export var TASK_RECUR_NONE = 0;
export var TASK_RECUR_WEEKLY = 1;
export var TASK_RECUR_MONTHLY = 2;
export var TASK_RECUR_QUARTERLY = 3;
export var TASK_RECUR_SEMI_ANNUALLY = 4;
export var TASK_RECUR_ANNUALLY = 6;
export var NOTE_ACTIVE = 0;
export var NOTE_DELETED = 99;
export var DOCT_ACTIVE = 0;
export var DOCT_DELETED = 99;
export var PRIV_NONE = 0;
export var PRIV_READ = 1;
export var PRIV_MANAGE = 2;
export var PRIV_WRITE = 3;
export var RELOWNER_NONE = 0;
export var RELOWNER_SECONDARY = 1;
export var RELOWNER_PRIMARY = 2;
export var RELOWNER_ANALYST = 3;
export var DATATABLE_NORMAL = 0;
export var DATATABLE_EXPORT = 1;
export var DATATABLE_ALL = 2;
export var ASSESSMENT_INHERENT = 1;
export var ASSESSMENT_RESIDUAL = 2;
export var ASSESSMENT_DILIGENCE = 2;
export var ASSESSMENT_REVIEW = 3;
export var ASSESSMENT_VENDORVALUE = 4;
export var ASSESSMENT_ONBOARDING = 7;
export var ASSESSMENT_OFFBOARDING = 8;
export var ASSESSMENT_CONCHECKLIST = 9;
export var ASSESSMENT_CONTRACT = 10;
export var THRESHOLD_INHERENT = 1;
export var THRESHOLD_RESIDUAL = 2;
export var THRESHOLD_VENDORVALUE = 4;
export var THRESHOLD_SLA = 5;
export var THRESHOLD_KPI = 6;
export var THRESHOLD_ONBOARDING = 7;
export var THRESHOLD_OFFBOARDING = 8;
export var THRESHOLD_CONCHECKLIST = 9;
export var THRESHOLD_CONTRACT = 10;
export var THRESH_ACTIVE = 0;
export var THRESH_DELETED = 99;
export var REPORT_ACTIVE = 0;
export var REPORT_DELETED = 99;
export var REPORT_TYPE_VENDORS = 1;
export var REPORT_TYPE_CONTRACTS = 2;
export var REPORT_TYPE_AUDIT = 3;
export var REPORT_TYPE_RELATIONSHIPS = 4;
export var WEIGHT_ACTIVE = 0;
export var WEIGHT_DELETED = 99;
export var RATING_INHERENT = 1;
export var RATING_RESIDUAL = 2;
export var RATING_VENDORVALUE = 4;
export var RATING_SLA = 5;
export var RATING_KPI = 6;
export var ANSRAT_ACTIVE = 0;
export var ANSRAT_DELETED = 99;
export var CONTRACT_STATUS_ACTIVE = 0;
export var CONTRACT_STATUS_DRAFT = 1;
export var CONTRACT_STATUS_TERMINATED = 2;
export var CONTRACT_STATUS_EXPIRED = 3;
export var CONTRACT_STATUS_ARCHIVED = 99;
export var SERVICE_ACTIVE = 0;
export var SERVICE_ARCHIVED = 99;
export var IMPORTANCE_ACTIVE = 0;
export var IMPORTANCE_DELETED = 99;
export var COSTCENTER_ACTIVE = 0;
export var COSTCENTER_DELETED = 99;
export var TVERS_ACTIVE = 0;
export var TVERS_DELETED = 99;
export var TVCOL_ACTIVE = 0;
export var TVCOL_DELETED = 99;
export var GROUP_ACTIVE = 0;
export var GROUP_DELETED = 99;
export var ATTRIBUTE_ACTIVE = 0;
export var ATTRIBUTE_DELETED = 99;
export var QCAT_ACTIVE = 0;
export var QCAT_DELETED = 99;
export var CONTRACT_QUESTION_ACTIVE = 0;
export var CONTRACT_QUESTION_DELETED = 99;
export var QUESTION_ACTIVE = 0;
export var QUESTION_DELETED = 99;
export var ANSWER_ACTIVE = 0;
export var ANSWER_DELETED = 99;
export var QUESTION_SET_ACTIVE = 0;
export var QUESTION_SET_DELETED = 99;
export var SECTION_ACTIVE = 0;
export var SECTION_DELETED = 99;
export var CONTRACT_STAGE_ACTIVE = 0;
export var CONTRACT_STAGE_PENDING = 1;
export var CONTRACT_STAGE_RENEWED = 2;
export var CONTRACT_STAGE_CANCELED = 3;
export var CONTRACT_STAGE_TERMINATED = 4;
export var CONTRACT_STAGE_EXPIRED = 5;
export var CONTRACT_TYPE_STANDARD = 0;
export var CONTRACT_TYPE_SUBSCRIPTION = 1;
export var CONTRACT_TYPE_PERPETUAL = 2;
export var BULKEXPORT_REQUESTED = 0;
export var BULKEXPORT_PROCESSING = 1;
export var BULKEXPORT_READY = 2;
export var BULKEXPORT_NORESULT = 3;
export var BULKEXPORT_EXPIRED = 99;
export var VEND_NOT_STARTED = 0;
export var VEND_IN_PROGRESS = 1;
export var VEND_COMPLETE = 2;
export var SURVEY_OPEN = 0;
export var SURVEY_COMPLETE = 1;
export var SURVEY_DELETED = 99;
export var SURVEY_TYPE_IR = 1;
export var SURVEY_TYPE_DD = 2;
export var SURVEY_TYPE_PR = 3;
export var SURVEY_TYPE_VV = 4;
export var SUBTASK_IR = SURVEY_TYPE_IR;
export var SUBTASK_DD = SURVEY_TYPE_DD;
export var SUBTASK_PR = SURVEY_TYPE_PR;
export var SUBTASK_RD = 50;
export var STK_METHOD_COMPLETED = 0;
export var STK_METHOD_DELETED = 1;
export var STK_METHOD_MARKED_COMPLETE = 2;
export var DOC_ACTIVE = 0;
export var DOC_DELETED = 99;
export var OPSCENTER_ACTIVE = 0;
export var OPSCENTER_DELETED = 99;
export var CAT_ACTIVE = 0;
export var CAT_DELETED = 66;
export var CAT_ARCHIVED = 99;
export var CONTACT_ACTIVE = 0;
export var CONTACT_DELETED = 99;
export var CRITICALITY_QUESTION_GUID = '443714ed0deab8bd83cd058f2de651d1';
export var CRITICALITY_SECTION_ID = 96;
export var TASK_ACTIVE = 0;
export var TASK_COMPLETE = 1;
export var TASK_OVERDUE = 2;
export var TASK_EXPIRED = 3;
export var TASK_CANCELLED = 4;
export var TASK_SCHEDULED = 5;
export var TASK_ARCHIVED = 99;
export var TASK_DELETED = 66;
export var TASKTYPE_NORMAL = 0;
export var TASKTYPE_REQDOC = 1;
export var TASK_NOTIFY_MYSELF = 1;
export var TASK_NOTIFY_ADMINS = 2;
export var TASK_NOTIFY_OWNERS = 3;
export var TASK_PROG_NOT_STARTED = 0;
export var TASK_PROG_IN_PROGRESS = 1;
export var TASK_PROG_COMPLETE = 2;
export var TASK_PROG_CANCELLED = 3;
export var ATTACH_ACTIVE = 0;
export var ATTACH_DELETED = 99;
export var FIELD_TEXT = 0;
export var FIELD_NUMBER = 1;
export var FIELD_DATE = 2;
export var FIELD_CURRENCY = 3;
export var FIELD_STATE = 4;
export var FIELD_SELECT = 5;
export var FIELD_COUNTRY = 6;
export var NOTIFY_NONE = 0;
export var NOTIFY_MANUAL = 1;
export var NOTIFY_AUTO = 2;
export var NOTIFY_MANUAL_AND_AUTO = 3;
export var REQDOC_ACTIVE_NOT_REQUIRED = 0;
export var REQDOC_ACTIVE_ONE_TIME = 1;
export var REQDOC_ACTIVE_SIX_MONTHS = 2;
export var REQDOC_ACTIVE_ANNUAL = 3;
export var REQDOC_ACTIVE_TWO_YEARS = 4;
export var TRANS_SUCCESS = 0;
export var TRANS_FAIL = 1;
export var BACKUP_ACTIVE = 0;
export var BACKUP_DELETED = 1;
export var LIB_ACTIVE = 0;
export var LIB_DELETED = 99;
export var LIB_TYPE_FILE = 0;
export var LIB_TYPE_LINK = 1;
export var WATCH_VENDOR_ACTIVE = 0;
export var WATCH_VENDOR_REMOVED = 1;
export var WATCH_VENDOR_DELETED = 99;
export var ACCESS_DETAILS = 0;
export var ACCESS_INHERENT = 1;
export var ACCESS_DILIGENCE = 2;
export var ACCESS_REVIEW = 3;
export var ACCESS_VALUE = 4;
export var ACCESS_CONTRACTS = 5;
export var ACCESS_ASSESSMENT = 6;
export var ACCESS_DOCUMENTS = 7;
export var SLA_RATINGS_LIMIT = 9;
export var SLA_SCORING_LIMIT = 7;
export var KPI_RATINGS_LIMIT = 9;
export var KPI_SCORING_LIMIT = 7;
export var MFA_METHOD_EMAIL = 0;
export var MFA_METHOD_SMS = 1;
export var MFA_METHOD_APP = 2;
export var AUDIT_SYSTEM = 0;
